/* the slides */
.slick-slide {
    height: auto !important;
    z-index: 50;
}

.slick-track {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    align-items: stretch !important;
}

.slick-slide > div {
    margin: 0 10px;
    height: 98%;
}

.slick-prev {
    background-color: #29618d !important;
    left: -10px !important;
    border-radius: 50%;
    z-index: 10;
}

.slick-list {
    overflow: visible !important;
}

.slick-next {
    background-color: #29618d !important;
    right: -10px !important;
    border-radius: 50%;
    z-index: 10;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0.85 !important;
}
