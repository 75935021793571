/* Overwrite Calendar component styles from react-date-range library*/
/* It is used in our library module DateInput */

@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';

.rdrCalendarWrapper {
    width: 210px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #c8cacb;
}

.rdrMonthAndYearWrapper {
    height: 25px !important;
    justify-content: center !important;
}

.rdrMonthAndYearPickers {
    flex: 1 1 !important;
    max-width: 100px !important;
}

.rdrMonthAndYearPickers select {
    padding-top: 0px;
}

.rdrMonthAndYearPickers select:hover {
    background-color: transparent !important;
}

.rdrNextPrevButton,
.rdrPprevButton {
    display: none !important;
}

.rdrMonth,
.rdrWeekDays,
.rdrDays {
    width: 210px;
    font-size: 10px;
    padding: 0 !important;
}
