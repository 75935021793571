@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './custom/calendar.css';
@import './custom/reactCarousel.css';
@import './custom/draft.css';

/* Hide number input increase/decrease buttons*/
@layer base {
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button,
    input[type='number'] {
        -webkit-appearance: none;
        margin: 0;
        appearance: none;
        -moz-appearance: textfield;
    }

    input[type='time']::-webkit-outer-spin-button,
    input[type='time']::-webkit-inner-spin-button,
    input[type='time'] {
        outline: none;
    }

    h1 {
        font-size: large;
    }
    h2 {
        font-size: larger;
    }
    h3 {
        font-size: medium;
    }
    ol {
        list-style: decimal;
        margin-left: 1.5rem;
    }
    ul {
        list-style-type: disc;
        margin-left: 1.5rem;
    }
}
