.DraftEditor-root {
    width: 100%;
    padding-bottom: 4px;
    font-size: 14px;
    line-height: 20px;
    color: #506281;
}

.toolbar {
    width: fit-content;
    height: 28px;
    border: #ddd;
    box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
    display: flex;
}

.buttonWrapper {
    display: flex;
}

.toolbar_button {
    width: 32px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: #ecf0f4;
}

.toolbar_button > svg {
    fill: #888;
    transform: scale(0.833);
}

.toolbar_button__active {
    background-color: #fff;
    box-shadow: none;
    border-bottom: 2px solid #3c90e5;
}

.toolbar_button__active > svg {
    fill: #506281;
}
